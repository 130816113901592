export function formatBytes(bytes: number, decimals = 2): { value: number; unit: string } {
    if (bytes === 0) {
        return { value: 0, unit: 'Bytes' };
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return {
        value: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
        unit: sizes[i],
    };
}

export const padNumber = (num: number, size: number): string => {
  let s = num + '';
  while (s.length < size) s = '0' + s;
  return s;
};

export const formatDate = (date: any, format = 'dd.MM.yyyy HH:mm:ss', skipTimezoneOffset = false): string => {
    if (!date || date === '') {
        return 'N/A';
    }

    let _date = new Date(date);
    if (!skipTimezoneOffset) {
        const timezoneOffset = _date.getTimezoneOffset() * 60 * 1000; // Get the local time zone offset in milliseconds
        _date = new Date(_date.getTime() - timezoneOffset);
    }
    const year = _date.getFullYear();
    const month = padNumber(_date.getMonth() + 1, 2); // months are 0-indexed
    const day = padNumber(_date.getDate(), 2);
    const hour = padNumber(_date.getHours(), 2);
    const minute = padNumber(_date.getMinutes(), 2);
    const second = padNumber(_date.getSeconds(), 2);

    return format.replace('yyyy', year.toString()).replace('MM', month).replace('dd', day).replace('HH', hour).replace('mm', minute).replace("ss", second);
};
