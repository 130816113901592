<template>
    <section class="section">
        <div class="container">
            <div class="columns is-multiline">
                <div class="column is-one-quarter" v-for="person in filteredPersons" :key="person.id">
                    <div class="box">
                        <div class="content has-text-centered" style="position:relative;">
                            <div class="actions">
                                <font-awesome-icon v-if="!showDelete" class="icon-circle"
                                    @click="showDelete = person.id" icon="close" />
                                <button v-if="showDelete" @click="removePerson(person.id)"
                                    class="button button-delete is-danger">{{ $t('General.Delete') }}</button>
                            </div>
                            <router-link :to="'/teams/' + person.id">
                                <figure class="image is-inline-block">
                                    <img class="is-rounded" v-if="person.profileImage" :src="person.profileImage"
                                        alt="Person's profile image" style="height: 230px;" />
                                    <img class="is-rounded" v-else src="../assets/media/profile.png"
                                        alt="Default profile image" style="height: 230px;" />
                                </figure>
                                <h2 class="subtitle is-5">{{ person.email }}</h2>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div v-if="filteredPersons.length === 0" class="column">
                    <h2 class="title has-text-centered">{{  $t('Team.NoPersonsFound') }}</h2>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import { computed, defineComponent, h, onMounted, onUnmounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from '@/store';
import { debounce } from '@/directives/validationDirective';

// Access the store
const store = useStore();
const { t } = useI18n();

// Computed property for getting the persons from the Vuex store
const filteredPersons = computed(() => store.getters['team/filteredPersons']);

const showDelete = ref("");

const SearchBar = defineComponent({
  setup() {
    const searchTerm = ref('');
    const debouncedSearch = debounce(async () => {
        store.dispatch('team/filterPersonsByEmail', searchTerm.value); // Trigger client-side filtering
    }, 250);
    const label = t('Team.SearchPlaceholder');
    // Return render function to match the structure and style
    return () =>
      h('div', { class: 'field material-input mb-0 mt-4' }, [
        h('div', { class: 'control' }, [
          h('input', {
            class: 'input',
            type: 'text',
            placeholder: '',
            value: searchTerm.value,
            onInput: (event: InputEvent) => {
              searchTerm.value = (event.target as HTMLInputElement).value;
              debouncedSearch();
            },
          }),
          h('label', { class: 'label' }, label), // Replace this with your i18n translation if needed
        ]),
      ]);
  },
});

// Set layout class in the store
store.commit('app/setLayout', 'default');

// Function to load the persons
const loadPersons = async (searchTerm?: string) => {
    console.log("searchTerms", searchTerm);
    await store.dispatch('team/getPersons');
};

// Function to remove a person from all projects
const removePerson = async (personId: string) => {
    await store.dispatch('team/removePersonFromAllProjects', personId);
    store.commit('app/showToast', {
        message: 'Person removed from all projects successfully.',
        type: 'success',
    });
};

onMounted(async () => {
    store.dispatch('app/updateNavbarContent', SearchBar);
    await loadPersons();
});
onUnmounted(() => {
    store.dispatch('app/clearNavbarContent');
});

</script>

<style scoped lang="scss">
.actions {
    z-index: 99;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5rem;
    font-size: 1.5rem;
    color: #666;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    width: 100%;
    text-align: right;

    .button-delete {
        width: 100%;
    }

    &:hover {
        color: #000;
    }

    .button-delete {
        margin-top: -10px;
    }
}

.box {
    height: 320px;
}
</style>
