import { ActionTree, GetterTree, MutationTree } from 'vuex';
import axios from 'axios';
import { SharedProjectView } from './team';

export interface SharedPersonView {
    id: string;
    email: string;
    firstName?: string;
    lastName?: string;
    profileImage?: string;
}

export interface ProjectShareState {
    sharedPersons: SharedPersonView[];
    name: string;
    id: string;
}

const state = (): ProjectShareState => ({
    sharedPersons: [],
    name: "",
    id: "",
});

const mutations: MutationTree<ProjectShareState> = {
    setSharedPersons(state: ProjectShareState, data: SharedProjectView) {
        console.log("Setting shared persons:", data);   
        state.sharedPersons = data.sharedPersons;
        state.name = data.name;
        state.id = data.id;
    },
    removePersonAccess(state: ProjectShareState, { personId }: { personId: string }) {
        state.sharedPersons = state.sharedPersons.filter(person => person.id !== personId);
    },
    removeAllPersons(state: ProjectShareState) {
        state.sharedPersons = [];
    },
};

const actions: ActionTree<ProjectShareState, any> = {
    // Fetch shared persons for a specific project
    async getSharedPersonsForProject({ commit }, projectId: string) {
        try {
            const response = await axios.get<SharedProjectView>(`/api/projectshare/${projectId}/shared-persons`);
            if (response.status === 200) {
                commit('setSharedPersons', response.data);
            }
        } catch (error) {
            console.error('Failed to fetch shared project and persons:', error);
            commit('setSharedPersons', null);
        }
    },

    // Remove a person's access from a specific project
    async deleteAccess({ commit }, { projectId, personId }: { projectId: string; personId: string }) {
        try {
            const response = await axios.delete(`/api/projectshare/${projectId}/person/${personId}/access`);
            if (response.status >= 200 && response.status <= 299) {
                commit('removePersonAccess', { personId });
            }
        } catch (error) {
            console.error('Failed to delete access:', error);
        }
    },

    // Invite users to the project by email
    async inviteUsers(_, { projectId, emails }: { projectId: string; emails: string }) {
        try {
            const response = await axios.post(`/api/projectshare/${projectId}/invite`, { emails });
            if (response.status >= 200 && response.status <= 299) {
                console.log('Users successfully invited to the project.');
            }
        } catch (error) {
            console.error('Failed to invite users:', error);
        }
    },

    // Remove all persons from a specific project
    async removeAllUsersFromProject({ commit }, projectId: string) {
        try {
            const response = await axios.delete(`/api/projectshare/${projectId}/remove-all-users`);
            if (response.status >= 200 && response.status <= 299) {
                commit('removeAllPersons');
                console.log('All users successfully removed from the project.');
            }
        } catch (error) {
            console.error('Failed to remove all users from the project:', error);
        }
    },
};

const getters: GetterTree<ProjectShareState, any> = {
    sharedPersons: (state: ProjectShareState) => {
        return state.sharedPersons;
    },
    projectName: (state: ProjectShareState) => {
        return state.name;
    },
    projectId: (state: ProjectShareState) => {
        return state.id;
    },
};

export const projectShareStore = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
