<template>
    <div id="app">
        <header class="header">
            <nav class="container navbar is-flex-touch is-justify-content-space-between" role="navigation"
                aria-label="main navigation">
                <div class="navbar-brand">
                    <a class="navbar-item" href="/">
                        <img src="./assets/Logo.png" alt="Logo" />
                    </a>
                </div>
                <div v-if="currentFile !== null || fileId !== ''" class="navbar-center">
                    <div class="columns is-centered mt-1 mb-4">
                        <div class="column has-text-centered">
                            <div v-if="currentFile !== null" class="has-text-centered">
                                {{ $t('Header.NoClose') }}
                            </div>
                            <template v-if="uploadedSize !== fileSize">
                                <p class="is-size-3 title">
                                    {{ uploadedSize }}{{ uploadedSizeUnit }} / {{ fileSize }} {{ fileSizeUnit }}
                                    <!-- ({{ uploadProgress }}%) -->
                                </p>
                            </template>
                            <template v-else-if="uploadedSize === fileSize && currentFile !== null">
                                <p class="is-size-3">{{ $t('Header.FileProcessing') }}</p>
                            </template>
                            <template v-else-if="uploadedSize === fileSize && !isAuth && fileId !== ''">
                                <p class="is-size-5 subtitle">
                                    {{ $t('Header.NoCloseLogin') }}
                                </p>
                            </template>
                        </div>
                    </div>
                    <div class="columns is-centered">
                        <div class="column is-two-thirds">
                            <progress v-if="uploadedSize !== fileSize" class="progress is-primary is-small"
                                style="width:100%;margin-bottom:0.5rem;" :value="uploadProgress" max="100">{{
                    uploadProgress
                }}%</progress>
                            <progress v-if="uploadedSize === fileSize" class="progress is-primary is-small"
                                style="width:100%;margin-bottom:0.5rem;"></progress>
                        </div>
                    </div>
                </div>
                <div class="navbar-end">
                    <div v-if="navbarContent">
                        <component :is="navbarContent"></component>
                    </div>

                    <!-- <div v-if="store.state.auth.isAuthenticated" class="navbar-item">
                        <MenuButton v-model="sidebarActive" />
                    </div> -->
                    <!-- <div v-if="!store.state.auth.isAuthenticated" class="navbar-item">
                        <router-link class="is-primary is-size-5" to="/login">{{ $t('Login.Title') }}</router-link>
                    </div>
                    <div v-if="store.state.auth.isAuthenticated" class="navbar-item">
                        <a @click="handleLogout">{{ $t('General.Menu.LogOut') }}</a>
                    </div> -->
                </div>
            </nav>
        </header>
        <div class="columns is-gapless">
            <div :class="{ active: sidebarActive }" class="column is-narrow sidebar">
                <!-- <transition mode="out-in" :name="'slide-fade'"> -->
                <Sidebar @selected="toggleSidebar" />
                <!-- </transition> -->
            </div>
            <div class="column content-column"
                :class="{ active: sidebarActive, 'is-flex is-align-items-center is-justify-content-center': layoutClass === 'centered' }">
                <!-- <video v-if="currentFile !== null" autoplay muted loop id="backgroundVideo">
                    <source src="@/assets/media/background-video.mp4" type="video/mp4">
                    Your browser does not support HTML5 video.
                </video> -->
                <router-view v-slot="{ Component }">
                    <transition mode="out-in" :name="'slide-fade'">
                        <component :is="Component" />
                    </transition>
                </router-view>
            </div>
        </div>
        <div class="chat-windows">
            <!-- Left arrow for switching chats -->
            <div class="navigate-container is-flex is-align-items-center is-justify-content-center">
                <button v-if="canShowPrev" @click="prevChatWindow" class="btn btn-primary icon-button navigate">
                    <font-awesome-icon icon="chevron-left" />
                </button>
            </div>

            <!-- Display active chats -->
            <chat-window v-for="chat in visibleChats" :key="chat.chatGroupId" :participants="chat.participants"
                :messages="chat.messages" :chatGroupId="chat.chatGroupId" />

            <!-- Right arrow for switching chats -->
            <div class="navigate-container is-flex is-align-items-center is-justify-content-center">
                <button v-if="canShowNext" @click="nextChatWindow" class="btn btn-primary icon-button navigate">
                    <font-awesome-icon icon="chevron-right" />
                </button>
            </div>
        </div>
        <footer class="footer">
            <div class="content has-text-centered">
                <p>
                    Cloud NYNE GmbH | Schönbrunner Str. 2/68 | 1040 Wien |
                    <a href="mailto:up@nyne.cloud">up@nyne.cloud</a> | <a href="https://nyne.cloud">nyne.cloud</a> |
                    <a href="https://nyne.cloud/terms-and-conditions" target="_blank">{{ $t('Footer.Terms') }}</a> |
                    <a href="https://nyne.cloud/privacy-policy" target="_blank">{{ $t('Footer.PrivacyPolicy') }}</a>
                </p>
            </div>
        </footer>
        <!-- <CookieBanner /> -->
        <Toast />
        <Transition :name="'fade'">
            <LoadingSpinner />
        </Transition>

        <ConfirmDialog />
    </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import Sidebar from './components/SideBar.vue';
import Toast from './components/Toast.vue';
import LoadingSpinner from './components/LoadingSpinner.vue';
// import MenuButton from './components/MenuButton.vue';
import ConfirmDialog from './components/ConfirmDialog.vue';
// import CookieBanner from './components/CookieBanner.vue';
import ChatWindow from './components/ChatWindow.vue';

import { useStore } from './store';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { onUnmounted } from 'vue';
import { debounce } from './directives/validationDirective';

const { t } = useI18n();
const sidebarActive = ref(false);
const store = useStore();
const router = useRouter();

const activeChats = computed(() => store.state.chat.activeChats);
const userId = computed(() => store.state.auth.userData.id);

// Configurable max number of visible chats
const maxVisibleChats = ref(2); // You can change this to a different value based on your needs
const currentChatIndex = ref(0); // Track the index of the first visible chat

const calculateMaxVisibleChats = () => {
    const availableWidth = window.innerWidth - 40; // Subtract some margin for the layout
    const chatWidth = 420; // Each chat window has approximately width of 400px + 10px gap
    maxVisibleChats.value = Math.max(1, Math.floor(availableWidth / chatWidth));
};

const visibleChats = computed(() => {
    const totalChats = activeChats.value.length;
    // Ensure we display the newest chats by slicing from the end
    if (totalChats <= maxVisibleChats.value) {
        return activeChats.value.slice(0, maxVisibleChats.value); // If less than 2 chats, show them all
    }
    return activeChats.value.slice(-maxVisibleChats.value - currentChatIndex.value, totalChats - currentChatIndex.value);
});

const canShowPrev = computed(() => currentChatIndex.value < (activeChats.value.length - maxVisibleChats.value));
const canShowNext = computed(() => currentChatIndex.value > 0);
onMounted(() => {
    if (store.state.auth.isAuthenticated) {
        calculateMaxVisibleChats();
        store.dispatch('chat/initializeConnection');
    }
});
watch(() => store.state.auth.isAuthenticated, (isAuthenticated) => {
    if (isAuthenticated) {
        store.dispatch('chat/initializeConnection');
    }
});

const currentFile = computed(() => store.state.upload.currentFile);
const fileSize = computed(() => store.state.upload.fileSize);
const fileSizeUnit = computed(() => store.state.upload.fileSizeUnit);
const uploadedSize = computed(() => store.state.upload.uploadedSize);
const uploadedSizeUnit = computed(() => store.state.upload.uploadedSizeUnit);
const uploadProgress = computed(() => store.state.upload.uploadProgress);
const fileId = computed(() => store.state.upload.fileId);
const isAuth = computed(() => store.state.auth.isAuthenticated);
const layoutClass = computed(() => store.state.app.layoutClass);
const navbarContent = computed(() => store.state.app.navbarContent);

const toggleSidebar = () => {
    sidebarActive.value = !sidebarActive.value;
};

// const handleLogout = async () => {
//     await store.dispatch('auth/logout');
//     router.push({ name: 'Login' });
// };
const timer = ref<any>(null);
const body = document.body;

const showScrollbarForOneSecond = () => {
    clearTimeout(timer.value);
    body.classList.add('show-scrollbar');

    timer.value = setTimeout(() => {
        body.classList.remove('show-scrollbar');
    }, 1000);
}

const debouncedShowScrollbar = debounce(showScrollbarForOneSecond, 10);
const debouncedSendReadReceipts = debounce(() => {
    sendReadReceipts();
}, 300);

const debouncedResizeMaxVisibleChats = debounce(() => {
    calculateMaxVisibleChats();
}, 300);

const sendReadReceipts = () => {
    activeChats.value.forEach(chat => {
        const isChatWithSelf = chat.participants.length === 1 && chat.participants[0].userId === userId.value;
        const unreadMessages = chat.messages.filter(message => {
            if (message.status !== 'delivered') {
                return false;
            }
            if (isChatWithSelf) {
                // In chats with self, we consider all messages
                return true;
            } else {
                // In chats with others, exclude messages sent by self
                return message.userId !== userId.value;
            }
        });
        if (unreadMessages.length > 0) {
            store.dispatch('chat/sendReadReceipt', { chatGroupId: chat.chatGroupId });
        }
    });
};

const prevChatWindow = () => {
    if (canShowPrev.value) {
        currentChatIndex.value = Math.min(currentChatIndex.value + 1, activeChats.value.length - maxVisibleChats.value);
    }
};

const nextChatWindow = () => {
    if (canShowNext.value) {
        currentChatIndex.value = Math.max(currentChatIndex.value - 1, 0);
    }
};

window.addEventListener('resize', debouncedResizeMaxVisibleChats);

window.addEventListener('mousemove', debouncedSendReadReceipts);
window.addEventListener('focus', debouncedSendReadReceipts);
window.addEventListener('click', debouncedSendReadReceipts);
window.addEventListener('keydown', debouncedSendReadReceipts);

window.addEventListener('mousemove', debouncedShowScrollbar);
window.addEventListener('scroll', debouncedShowScrollbar);
window.addEventListener('touchstart', debouncedShowScrollbar);
window.addEventListener('touchmove', debouncedShowScrollbar);
window.addEventListener('touchend', debouncedShowScrollbar);

window.addEventListener('beforeunload', () => {
    store.dispatch('chat/closeConnection');
});
onBeforeUnmount(() => {
    store.dispatch('chat/closeConnection');
});

onUnmounted(() => {
    window.removeEventListener('resize', debouncedResizeMaxVisibleChats);

    window.removeEventListener('mousemove', debouncedSendReadReceipts);
    window.removeEventListener('focus', debouncedSendReadReceipts);
    window.removeEventListener('click', debouncedSendReadReceipts);
    window.removeEventListener('keydown', debouncedSendReadReceipts);

    window.removeEventListener('mousemove', debouncedShowScrollbar);
    window.removeEventListener('scroll', debouncedShowScrollbar);
    window.removeEventListener('touchstart', debouncedShowScrollbar);
    window.removeEventListener('touchmove', debouncedShowScrollbar);
    window.removeEventListener('touchend', debouncedShowScrollbar);

});


// Watch for changes in the number of active chats and reset the index if necessary
watch(() => activeChats.value.length, (newLength) => {
    if (currentChatIndex.value + maxVisibleChats.value > newLength) {
        currentChatIndex.value = Math.max(0, newLength - maxVisibleChats.value);
    }
});

watch(
    () => router.currentRoute.value.meta.title,
    newTitle => {
        if (newTitle) {
            (document as any).title = t(newTitle.toString()) + ' | ' + 'Cloud NYNE';
        } else {
            document.title = 'Cloud NYNE'; // Set a default title if no specific title is provided
        }
    },
);

watch(
    () => store.state.upload.isUploading,
    (isUploading) => {
        if (isUploading) {
            window.addEventListener('beforeunload', handleBeforeUnload);
        } else {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        }
    }
);

// Function to handle the beforeunload event
const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    if (store.state.upload.isUploading) {
        event.preventDefault();
        event.returnValue = ''; // Most browsers will show a generic warning dialog
    }
};

</script>



<style scoped lang="scss">
@import '@/assets/css/variables.scss';

.slide-fade-enter-active {
    transition: all 0.35s ease-in-out;
}

.slide-fade-leave-active {
    // transition: all 0.4s ease-in-out;
    transition: all 0.35s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from {
    transform: translateX(15%);
    opacity: 0;
}

.slide-fade-leave-to {
    transform: translateX(-35%);
    opacity: 0;
}

.fade-enter-active {
    animation: toast-fade-in 0.4s ease-in-out;
}

.fade-leave-active {
    animation: toast-fade-out 0.4s ease-in-out;
}

@keyframes toast-fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes toast-fade-out {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.header {
    padding: 15px 30px;
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;

    .navbar {
        background-color: transparent;
        /* green color */
    }

    .container.navbar {
        @media screen and (min-width: 1024px) {
            max-width: 1000px;
        }

        @media screen and (min-width: 1216px) {
            &:not(.is-max-desktop) {
                max-width: 1192px;
            }
        }

        @media screen and (min-width: 1408px) {
            &:not(.is-max-desktop):not(.is-max-widescreen) {
                max-width: 1380px;
            }
        }
    }

    .navbar-item a {
        font-weight: 500;
        text-decoration: none;
        margin-right: 10px;

        &:hover {
            text-decoration: underline;
        }
    }

    .navbar-brand .navbar-item img {
        width: 127px;
        max-height: unset;
        margin-top: 8px;
    }

    .navbar-center {
        width: 50%;
        min-width: 600px;

        .column {
            padding: 0;
        }

        padding-bottom: 0.5rem;
    }

    .navbar-end {
        margin-left: 0;

        @media screen and (max-width: 1024px) {
            margin-top: 8px;
        }

        button {
            color: #b71c1c;
            /* red color */
            background-color: white;
        }
    }

    .progress.is-small {
        height: 0.25rem;
        // background-color: white;
    }
}

#app>.columns.is-gapless section {
    // margin-top: -130px;
    width: 100%;
}

.columns.is-gapless {
    margin-right: -1px;
    height: calc(100vh - 100px);
    // margin-top: -175px;
    z-index: 1;
    margin-top: 70px;

    &:not(:last-child) {
        margin-bottom: 0;
    }
}

.chat-windows {
    position: fixed;
    bottom: 20px;
    right: 0;
    display: flex;
    flex-wrap: nowrap;
    gap: 10px; // Space between chat windows
    max-width: calc(100vw - 40px); // Dynamic width, but will not overflow full width of the page
    overflow-x: auto; // Scrollable if too many chats are open
}

.navigate-container {
    .btn-primary {
        &.icon-button.navigate {
            background-color: lightgray;
            color: white;
            border: none;
            cursor: pointer;
            padding: 0.5rem;
            font-size: 1.5rem;
            height: 50px;
        }
    }
}
</style>
