<template>
    <div class="chat-container box p-1">
        <!-- Left arrow for switching chats -->
        <div class="chat-window">
            <div class="chat-participants">
                <div v-for="user in participants" :key="user.userId" class="chat-participant">
                    <img :src="getSrc(user.userId)" @error="setDefaultProfileImage($event)" alt="User profile image" />
                    <p>{{ user.name }}</p>
                </div>
                <button class="btn btn-primary icon-button" @click="closeChat()">
                    <font-awesome-icon class="icon-circle" icon="close" />
                </button>
            </div>

            <div class="chat-messages" ref="chatMessagesRef">
                <div v-for="(message, index) in messages" :key="index" :class="messageClass(message)">
                    <p class="text">{{ message.text }}</p>
                    <br />
                    <small :title="formatFullDate(message.createdAt)">{{ formatDate(message.createdAt) }}</small>
                    <!-- Checkmark for sent messages -->
                    <span v-if="message.userId === userId && message.status === 'sent'">
                        <font-awesome-icon class="fontawesome-icon sent" icon="clock-rotate-left" />
                    </span>
                    <span v-if="message.userId === userId && message.status === 'delivered'">
                        <font-awesome-icon class="fontawesome-icon delivered" icon="check" />
                    </span>
                    <span v-if="message.userId === userId && message.status === 'read'">
                        <font-awesome-icon class="fontawesome-icon delivered" :icon="['far', 'check-square']" />
                    </span>
                </div>
            </div>

            <div class="chat-input">
                <input class="input is-small" v-model="newMessage" @keydown.enter="sendMessage"
                    placeholder="Type a message..." />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { useStore } from '@/store';
import { Participant, Message } from '@/store/modules/chat'
import ProfileImage from '../assets/media/profile.png';

const props = defineProps({
    participants: Array<Participant>,
    messages: Array<Message>,
    chatGroupId: String
});

const store = useStore();
const newMessage = ref('');
const chatMessagesRef = ref<HTMLElement | null>(null);
const userDataLanguage = computed(() => store.state.auth.userData?.language || 'en');

onMounted(() => {
    if (props.messages?.length === 0) {
        store.dispatch('chat/loadChatHistory', props.chatGroupId);
    }

    nextTick(() => {
        if (chatMessagesRef.value) {
            chatMessagesRef.value.scrollTop = chatMessagesRef.value.scrollHeight;
        }
    });
});
watch(
    () => props.messages,
    () => {
        nextTick(() => {
            if (chatMessagesRef.value) {
                chatMessagesRef.value.scrollTop = chatMessagesRef.value.scrollHeight;
            }
        });
    },
    { deep: true }
);

const userId = computed(() => store.state.auth.userData?.id);
const baseImageUrl = window.origin.includes('localhost') ? 'http://localhost:5212/api/auth/' : '/api/auth/';
const setDefaultProfileImage = (event: Event) => {
    const target = event.target as HTMLImageElement;
    target.src = ProfileImage;
};
const getSrc = (userId: string) => {
    // console.log(userId, baseImageUrl);
    // 
    return `${baseImageUrl}${userId}/profile`;
    // const imagePath = baseImageUrl + ProfileImage
    // return user?.profileImage || '';
};

const sendMessage = () => {
    if (newMessage.value.trim()) {
        store.dispatch('chat/sendMessage', { userId: userId.value, message: newMessage.value, chatGroupId: props.chatGroupId });
        newMessage.value = '';
    }
};
const closeChat = () => {
    store.dispatch('chat/closeChat', props.chatGroupId);
};
const formatDate = (date?: Date) => {
    if (!date) return '';
    date = new Date(date);
    if (isNaN(date.getTime())) return 'N/A';
    return new Intl.DateTimeFormat(userDataLanguage.value || 'en', { hour: 'numeric', minute: 'numeric', hour12: false }).format(new Date(date));
};

const formatFullDate = (date: Date) => {
    if (!date) return '';
    date = new Date(date);
    if (isNaN(date.getTime())) return 'N/A';
    return new Intl.DateTimeFormat(userDataLanguage.value || 'en', { year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric', hour12: false }).format(new Date(date));
}

const messageClass = (message: { userId: string | null }) => {
    if (message.userId === null) return 'message-center';
    return message.userId === userId.value ? 'message-right' : 'message-left';
};

</script>


<style scoped lang="scss">
$primary: #444;
$border-light: #e5e5e5;
$background-light: #f5f5f5;

.chat-container {
    width: 400px;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0;
    border-radius: 34px;
    background-color: #f5f5f5; // Bulma's light background color
    z-index: 10;
    margin-bottom: 0 !important;
    overflow: hidden; // Prevent overflow on resize
    resize: horizontal;
    direction: rtl;

    .chat-window {
        direction: ltr;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 500px; // Adjust based on your requirements
        box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1); // Bulma's box-shadow
        border: 1px solid $border-light;
        border-radius: 22px;

        .chat-header,
        .chat-participants {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: $primary;
            color: white;
            padding: 0.5rem;
            border-bottom: 1px solid $border-light;
            border-top-left-radius: 22px;
            border-top-right-radius: 22px;
            img {
                background-color: white;
            }
        }

        .chat-participants {
            gap: 1rem;
            padding: 0.5rem;

            .chat-participant {
                text-align: center;

                img {
                    border-radius: 50%;
                    width: 40px;
                    height: 40px;
                    object-fit: cover;
                }

                p {
                    font-size: 0.8rem;
                    margin-top: 0.2rem;
                }
            }
        }

        .chat-messages {
            padding: 1rem;
            overflow-y: auto;
            flex-grow: 1;
            background-color: white;
            display: flex;
            flex-direction: column;

            .text {
                display: inline;
            }

            .fontawesome-icon {
                margin-left: 0.35rem;
                margin-bottom: -0.1rem;

                &.sent {
                    color: gray;
                }

                &.delivered {
                    color: green;
                }
            }

            .message,
            .message-left,
            .message-right {
                display: inline-block;
                padding: 0.5rem 1rem;
                border-radius: 1rem;
                margin-bottom: 0.5rem;
                max-width: 90%;
                word-break: break-word;
            }

            .message-left {
                background-color: $background-light;
                align-self: flex-start;
            }

            .message-right {
                background-color: $primary;
                color: white;
                align-self: flex-end;
                margin-left: auto;
            }

            .message-center {
                text-align: center;
                color: gray;
                font-size: 0.8rem;
                margin: 1rem 0;
            }
        }

        .chat-input {
            padding: 0.75rem;
            background-color: white;
            border-top: 1px solid $border-light;
            border-bottom-left-radius: 22px;
            border-bottom-right-radius: 22px;

            .input {
                width: 100%;
                border-radius: 1.5rem; // Flatten the input like Bulma's form controls
            }
        }
    }

    .btn-primary {
        &.icon-button {
            position: absolute;
            right: 15px;
            top: 25px;
            background-color: transparent;
            color: white;
            border: none;
            cursor: pointer;
            padding: 0.5rem 1rem;
            font-size: 1.5rem;
        }
    }
}
</style>