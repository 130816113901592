import { ChatHistoryView } from '@/models/chatHistory.model';
import { LogLevel, HubConnectionBuilder, HubConnection } from '@microsoft/signalr';
import axios from 'axios';
import { ActionTree, MutationTree } from 'vuex';

export interface ChatState {
    connection: HubConnection | null;
    activeChats: ActiveChat[];
}
export interface Message {
    userId: string | null; // null for system messages
    text: string;
    status: 'sent' | 'delivered' | 'read';
    createdAt: Date;
}

export interface Participant {
    userId: string;
    // profileImage: string;
    name: string;
}

export interface ActiveChat {
    // userId: string; // The user initiating the chat
    chatGroupId: string;
    participants: Participant[];
    messages: Message[];
}

const mutations: MutationTree<ChatState> = {
    setConnection(state, connection: HubConnection) {
        state.connection = connection;
    },
    // Add a new chat if there isn't one already with the same user
    startChat(state, { accountIds, chatGroupId }: { accountIds: string[]; chatGroupId: string }) {
        const existingChat = state.activeChats.find(chat => chat.chatGroupId === chatGroupId);
        const newParticipants = accountIds.map(x => ({ userId: x, name: x }));

        if (!existingChat) {
            state.activeChats.push({ chatGroupId, participants: newParticipants, messages: [] });
        } else {
            existingChat.participants = [...existingChat.participants, ...newParticipants].filter(
                (v, i, a) => a.findIndex(t => t.userId === v.userId) === i,
            );
        }
    },

    setChatHistory(state, { chatGroupId, chatHistory }: { chatGroupId: string; chatHistory: ChatHistoryView }) {
        const chat = state.activeChats.find(chat => chat.chatGroupId === chatGroupId);
        const mappedChatLogs: Message[] = chatHistory.chatLogs.map(log => ({
            userId: log.createdById,
            text: log.message,
            createdAt: new Date(log.dateCreated),
            status: 'read',
        }));
        const mappedParticipants: Participant[] = chatHistory.participants.map(participant => ({ userId: participant, name: participant }));
        if (chat) {
            chat.messages = [...mappedChatLogs, ...chat.messages];
            if (chat.participants) {
                chat.participants = [...mappedParticipants, ...chat.participants].filter(
                    (v, i, a) => a.findIndex(t => t.userId === v.userId) === i,
                );
            }
        } else {
            const data: ActiveChat = {
                chatGroupId,
                participants: mappedParticipants,
                messages: mappedChatLogs,
            }
            state.activeChats.push(data);
        }
    },

    addMessage(
        state,
        {
            userId,
            message,
            chatGroupId,
            createdAt,
            status = 'sent',
        }: { userId: string; message: string; chatGroupId: string; createdAt: Date; status?: 'sent' | 'delivered' | 'read' },
    ) {
        const chat = state.activeChats.find(chat => chat.chatGroupId === chatGroupId);
        if (chat) {
            const messageExists = chat.messages.find(msg => msg.createdAt.getTime() === createdAt.getTime());
            if (!messageExists) {
                chat.messages.push({
                    userId: userId,
                    text: message,
                    createdAt: new Date(createdAt),
                    status: status,
                });
            } else {
                messageExists.status = status;
            }          
        }
    },
    updateMessageStatus(
        state,
        { chatGroupId, createdAt, status }: { chatGroupId: string; createdAt: Date; status: 'sent' | 'delivered' | 'read' },
    ) {
        const chat = state.activeChats.find(chat => chat.chatGroupId === chatGroupId);
        console.log('UPDATING MESSAGE STATUS', chatGroupId, createdAt, status, chat);
        if (chat) {
            console.log('found chat', chat.messages);
            const message = chat.messages.find(msg => msg.createdAt.getTime() === new Date(createdAt).getTime());
            console.log('foundMessage', message, new Date(createdAt).getTime());
            console.log('comparato', message?.createdAt.getTime());
            if (message) {
                message.status = status;
            }
        }
    },
    updateAllMessagesStatus(state, { chatGroupId, status }: { chatGroupId: string; status: 'sent' | 'delivered' | 'read' }) {
        const chat = state.activeChats.find(chat => chat.chatGroupId === chatGroupId);
        if (chat) {
            chat.messages.forEach(message => {
                if (message.status === 'delivered') {
                    message.status = status;
                }
            });
        }
    },
    closeChat(state, chatGroupId) {
        state.activeChats = state.activeChats.filter(chat => chat.chatGroupId !== chatGroupId);
    },
};

const actions: ActionTree<ChatState, any> = {
    // Initialize the SignalR connection
    async initializeConnection({ commit, rootState, state, dispatch }) {
        if (state.connection) return; // Prevent reinitializing the connection

        const token = localStorage.getItem('nynecloud_accessToken') ?? '';
        const chathubUrl = window.origin.includes('localhost') ? 'http://localhost:5212/api/chatHub' : '/api/chatHub';
        const connection = new HubConnectionBuilder()
            .withUrl(chathubUrl, {
                accessTokenFactory: () => token,
            })
            .configureLogging(LogLevel.Debug)
            .build();

        const userId = rootState.auth.userData.id; // Access the userId from the auth module

        connection.on('ReceiveMessage', (fromAccountId: string, message: string, chatGroupId: string, createdAt: Date) => {
            console.log('Received message', fromAccountId, message, chatGroupId, createdAt, userId);
            const createdAtDate = new Date(createdAt);

            // if (fromAccountId === userId) {
            //     // Update the message we sent with the 'createdAt' timestamp
            //     commit('updateMessageStatus', { chatGroupId, createdAt: createdAtDate, status: 'delivered' });
            // } else {
                // Add the received message as usual for other users
            commit('addMessage', { userId: fromAccountId, message, chatGroupId, createdAt: createdAtDate, status: 'delivered' });
            // }
        });

        connection.on('ReceiveChatRequest', (fromAccountId: string, message: string, chatGroupId: string) => {
            console.log('Received chat request', fromAccountId, message, chatGroupId);
            const accountIds = userId === fromAccountId ? [fromAccountId] : [userId, fromAccountId];
            commit('startChat', { accountIds: accountIds, chatGroupId });
            console.log('RECEIVED CHATREQUEST', fromAccountId, message, chatGroupId);
            const currentChat = state.activeChats.find(chat => chat.chatGroupId === chatGroupId);
            if (currentChat?.messages.length === 0) {
                dispatch('loadChatHistory', { chatGroupId });
            }
        });

        connection.on('ChatRequestSent', (toAccountIds: string[], chatGroupId: string) => {
            console.log('RECEIVED CHATREQUESTSENT', toAccountIds, chatGroupId);
            // commit('startChat', { accountIds: toAccountIds, chatGroupId });
            // dispatch('loadChatHistory', { chatGroupId });            
        });

        connection.on('MessagesRead', (chatGroupId: string) => {
            console.log('MessagesRead', chatGroupId);
            commit('updateAllMessagesStatus', { chatGroupId, status: 'read' });
        });

        connection.on('AddedToChat', (chatGroupId: string) => {
            console.log(`You have been added to chat group: ${chatGroupId}`);
            commit('addMessage', { userId: null, message: `You have been added to chat group: ${chatGroupId}` });
        });

        await connection.start();
        commit('setConnection', connection);
    },

    async loadChatHistory({ commit }, { chatGroupId }) {
        if (!chatGroupId) return;
        try {
            const copyChatGroup = chatGroupId + '';
            console.log("loadChatHistory()", copyChatGroup);
            const response = await axios.get<ChatHistoryView>('/api/chat/' + copyChatGroup);
            if (response.status >= 200 && response.status < 300) {
                commit('setChatHistory', { chatGroupId, chatHistory: response.data });
                return response.data;
            }
        } catch (error) {
            console.log('error', error);
            return null;
        }
    },

    // Start a new chat with a user (only if a chat with the same user is not already active)
    async startChat({ commit, state, rootState, dispatch }, { userId }: { userId: string }) {
        const existingChat = state.activeChats.find(
            chat => chat.participants.length === 2 && chat.participants.some(participant => participant.userId === userId),
        );
        if (!existingChat) {
            if (!state.connection) {
                await dispatch('initializeConnection');
            }

            if (state.connection) {
                const authUserId = rootState.auth.userData.id;
                const message = 'Starting Chat Request';
                const chatGroupId = await state.connection.invoke('SendChatRequest', [authUserId, userId], message);
                console.log("initiate conncetion", authUserId, userId, chatGroupId);
                commit('startChat', { accountIds: [authUserId, userId], chatGroupId });
            }
        }
    },

    async sendChunkedMessage(
        { state },
        { userId, message, chatGroupId, createdAt }: { userId: string; message: string; chatGroupId: string; createdAt: Date },
    ) {
        const chunkSize = 1024; // 1 KB chunk size
        const totalChunks = Math.ceil(message.length / chunkSize);
        const messageId = await generateMessageHash(message, createdAt);

        let lastChunkSent = 0;

        console.log('CHUNKED', userId, message, chatGroupId, messageId);

        const progressKey = `chatProgress-${chatGroupId}-${messageId}`;
        const savedProgress = localStorage.getItem(progressKey);

        if (savedProgress) {
            lastChunkSent = parseInt(savedProgress);
        }

        for (let i = lastChunkSent; i < totalChunks; i++) {
            const chunk = message.slice(i * chunkSize, (i + 1) * chunkSize);
            try {
                await state.connection?.invoke('SendMessageChunk', userId, chunk, chatGroupId, messageId, createdAt);

                // await state.connection?.invoke('SendMessageChunk', userId, chunk, chatGroupId, messageId);
                localStorage.setItem(progressKey, (i + 1).toString());
            } catch (error) {
                console.error(`Failed to send chunk ${i}:`, error);
                return;
            }
        }

        await state.connection?.invoke('MessageComplete', userId, chatGroupId, messageId, createdAt);
        // await state.connection?.invoke('MessageComplete', userId, chatGroupId, messageId);
        localStorage.removeItem(progressKey);
        // commit('addMessage', { userId, message, chatGroupId, createdAt, status: 'delivered' });
    },

    // Send a message to a specific user (now uses chunking)
    async sendMessage({ commit, dispatch }, { userId, message, chatGroupId }: { userId: string; message: string; chatGroupId: string }) {
        // Use the chunked sending method
        const createdAt = new Date();
        commit('addMessage', { userId, message, chatGroupId, createdAt: createdAt, status: 'sent' });

        console.log('ACTION/DISPATCH SendMessage()', userId, message, chatGroupId, createdAt);
        await dispatch('sendChunkedMessage', { userId, message, chatGroupId, createdAt });
    },

    async sendReadReceipt({ state }, { chatGroupId }: { chatGroupId: string }) {
        if (state.connection) {
            await state.connection.invoke('SendReadReceipt', chatGroupId);
        }
    },

    async addParticipantsToChat({ state }, { chatGroupId, newParticipantIds }) {
        if (state.connection) {
            await state.connection.invoke('AddParticipantsToChat', chatGroupId, newParticipantIds);
            console.log('Added participants to chat', chatGroupId, newParticipantIds);
        }
    },

    // // Send a message to a specific user
    // async sendMessage({ state, commit }, { userId, message, chatGroupId }: { userId: string, message: string, chatGroupId: string }) {
    //     if (state.connection) {
    //         console.log("XX Sending message", userId, message, chatGroupId);
    //         await state.connection.invoke('SendMessageToUser', userId, message, chatGroupId);
    //         commit('addMessage', { userId, message });
    //     }
    // },

    // Close the chat connection
    async closeConnection({ state, commit }) {
        if (state.connection) {
            await state.connection.stop();
            commit('setConnection', null); // Remove the connection from the state
        }
    },

    // Close a specific chat window (does not close the SignalR connection)
    closeChat({ commit }, chatGroupId: string) {
        commit('closeChat', chatGroupId);
    },

    async deleteAllChats() {
        try {
            const response = await axios.delete<ChatHistoryView>('/api/chat');
            if (response.status >= 200 && response.status < 300) {
                return response.data;
            }else{
                console.error('ERROR', response);
                return "ERROR";
            }
        } catch (error) {
            console.log('error', error);
            return [];
        }
    }
};

async function generateMessageHash(message: string, createdAt: Date): Promise<string> {
    const fingerprint = localStorage.getItem('nynecloud_accessToken') ?? '';
    const data = fingerprint + (message.length > 10 ? message.substring(0, 9) : message) + createdAt.toISOString();
    return await generateHash(data);
}

async function generateHash(value: string): Promise<string> {
    const data = new TextEncoder().encode(value); // Combine userId, fingerprint, and message
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
}

export const chatStore = {
    namespaced: true,
    state: {
        connection: null,
        activeChats: [],
    },
    mutations,
    actions,
};
