<template>
    <div class="loading-circle-container">
      <div class="circle-background">
        <svg class="progress-circle" viewBox="0 0 36 36">
          <path
            class="circle-bg"
            d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <path
            class="circle"
            :style="{ strokeDasharray: getPercentage + ', 100' }"
            d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
          />
        </svg>
        <div class="percentage-text">
          {{ percentage }}
        </div>
      </div>
    </div>
  </template>
  
  <script setup lang="ts">
  import { computed } from 'vue';
  
  const props = defineProps({
    percentage: {
      type: String, // The percentage is a string, e.g., "45.67%"
      required: true,
    }
  });
  
  // Extract the numeric value from the percentage string (e.g., "45.67%")
  const getPercentage = computed(() => {
    const numericValue = parseFloat(props.percentage.replace('%', ''));
    // Ensure the percentage is between 0 and 100
    return Math.min(Math.max(numericValue, 0), 100);
  });
  </script>
  
  <style scoped lang="scss">
  .loading-circle-container {
    position: relative;
    width: 125px;
    height: 125px;
  }
  
  .circle-background {
    position: relative;
    width: 125px;
    height: 125px;
  }
  
  .progress-circle {
    width: 100%;
    height: 100%;
    transform: rotate(0deg); /* Start the circle progress at the top */
  }
  
  .circle-bg {
    fill: none;
    stroke: rgba(255, 255, 255, 0.3); /* Light white background */
    stroke-width: 2.8;
  }
  
  .circle {
    fill: none;
    stroke: #ffffff; /* White color for the progress */
    stroke-width: 2.8;
    stroke-linecap: round;
    stroke-dasharray: 0, 100;
    transition: stroke-dasharray 0.6s ease 0s;
  }
  
  .percentage-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5rem;
    font-weight: normal;
    color: #ffffff; /* White color for the text */
  }
  </style>
  