<template>
    <section class="section">
        <div class="container">
            <!-- <h1 class="title is-2 has-text-centered">{{ $t('Projects.Title') }}</h1> -->
            <div class="columns is-multiline">
                <div class="column is-one-quarter" v-for="project in filteredProjects" :key="(project.id)">
                    <div class="box">
                        <div class="content has-text-centered project"
                            :class="{ 'processing': !isProjectFinished(project.status) }" style="position:relative;">
                            <div class="actions">
                                <font-awesome-icon
                                    v-if="(!isProjectFinished(project.status) || store.state.auth.isAdmin) && showDelete !== project.id"
                                    class="icon-circle" @click="showDelete = project.id" icon="close" />
                                <button v-if="showDelete === project.id" @click="deleteProject(project.id)"
                                    class="button button-delete is-danger">{{ $t('General.Delete') }}</button>
                                <i v-if="store.state.auth.isAdmin" title="Reset project"
                                    @click="resetProject(project.id)" class="nyne-icon"
                                    :class="'nyne-icon nyne-icon-einstellungen'"></i>
                                <!-- <font-awesome-icon @click="deleteProject(project.id)" icon="trash"></font-awesome-icon> -->
                            </div>
                            <p v-if="!isProjectFinished(project.status)"
                                class="has-text-centered processing-text mt-1 mb-3 subtitle is-size-5">
                                {{ $t('Projects.Processing') }}
                            </p>
                            <router-link :to="'/project/' + project.id">
                                <!--     || previewImgs.indexOf(project.id) === -1 -->
                                <div v-if="!isProjectFinished(project.status)" class="loading-info is-flex">
                                    <div class="spinner-wrapper">
                                        <loading-spinner-white
                                            :percentage="getCurrentProjectStatePercentage(project.status)"></loading-spinner-white>
                                    </div>
                                </div>
                                <template v-else>
                                    <img v-show="previewImgs.indexOf(project.id) > -1"
                                        @load="loadedPreviewImage(project.id)" style="max-height: 160px;margin-top:24px;"
                                        v-loadimage="{ 'projectId': project.id }" alt="Project preview" />
                                    <img v-show="previewImgs.indexOf(project.id) === -1" style="max-height: 160px;margin-top:24px;"
                                        src="../assets/media/project_preview.png" alt="Project preview" />
                                </template>

                                <h2 class="subtitle is-4 mt-5 mb-0">{{ project.name || '&nbsp;' }}</h2>
                                <div v-if="store.state.auth.isAdmin">
                                    <p>{{ $t('ProjectDetail.Status') }}: {{ project.status }}
                                        <br />
                                        Tries: {{ project.tries }}
                                    </p>
                                    <span class="">{{ formatDate(project.dateCreated, userDateFormat + ' HH:mm', true)
                                        }}</span>
                                    <p>{{ $t('General.LastUpdate') }}:
                                        {{ formatDate(project.dateModified, userDateFormat + ' HH:mm', true) }}</p>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div v-if="filteredProjects.length === 0" class="column">
                    <h2 class="title has-text-centered">{{ $t('Projects.NotUploadedYet') }}</h2>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import { computed, defineComponent, h, onMounted, onUnmounted, reactive, ref } from 'vue';
import { useStore } from '../store';
import { formatDate } from '@/utils/formatBytes';
import { getCurrentProjectStatePercentage } from '@/utils/helper';
import { useI18n } from 'vue-i18n';
import LoadingSpinnerWhite from './LoadingSpinnerWhite.vue';
import { debounce } from '@/directives/validationDirective';

const { t } = useI18n();
const store = useStore();
const filteredProjects = computed(() => store.state.project.filteredProjects);
const previewImgs: string[] = reactive([]);
const showDelete = ref("")

// get store userdata dateFormat
const userDateFormat = computed(() => store.state.auth.userData?.dateFormat ?? 'dd.MM.yyyy');
const isProjectFinished = (status?: string) => {
    // SHOULD ONLY BE FILE_CONVERSION_FINISHED, CURRENTLY THE XKT-RUNNER DOESNT MOVE THE STATUS TO FILE_PROCESSING_FINISHED
    return status === 'XKT_CONVERSION_FINISHED' || status === 'FILE_PROCESSING_FINISHED' || status?.includes('_FAILED');
};

const SearchBar = defineComponent({
    setup() {
        const searchTerm = ref('');
        const debouncedSearch = debounce(async () => {
            store.dispatch('project/filterProjects', searchTerm.value);
        }, 250);

        const label = t('Projects.SearchPlaceholder');
        // Return render function to match the structure and style
        return () =>
            h('div', { class: 'field material-input mb-0 mt-4' }, [
                h('div', { class: 'control' }, [
                    h('input', {
                        class: 'input',
                        type: 'text',
                        placeholder: '',
                        value: searchTerm.value,
                        onInput: (event: InputEvent) => {
                            searchTerm.value = (event.target as HTMLInputElement).value;
                            debouncedSearch();
                        },
                    }),
                    h('label', { class: 'label' }, label), // Replace this with your i18n translation if needed
                ]),
            ]);
    },
});


store.commit('app/setLayout', 'default');

const loadedPreviewImage = (id: string) => {
    previewImgs.push(id);
};
const deleteProject = (id: any) => {
    const title = 'Delete Project';
    const text = t('Projects.Delete.Text');
    const action = async () => {
        await store.dispatch('project/deleteProject', id);

        store.commit('app/showToast', {
            message: t('Projects.Delete.Success'),
            type: 'success',
        });
    };

    store.commit('app/showConfirmDialog', { title, text, action });
};

const resetProject = (id: any) => {
    const title = 'Reset Project';
    const text = t('Projects.Reset.Text');
    const action = async () => {
        await store.dispatch('project/resetProject', id);

        store.commit('app/showToast', {
            message: t('Projects.Reset.Success'),
            type: 'success',
        });
    };

    store.commit('app/showConfirmDialog', { title, text, action });
};

const loadProjects = async (searchTerm?: string) => {
    console.log("searchTerms", searchTerm);
    await store.dispatch('project/getProjects');
}

onMounted(async () => {
    store.dispatch('app/updateNavbarContent', SearchBar);
    await loadProjects();
});
onUnmounted(() => {
    store.dispatch('app/clearNavbarContent');
});
</script>

<style scoped lang="scss">
.actions {
    z-index: 99;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5rem;
    font-size: 1.5rem;
    color: #666;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    width: 100%;
    text-align: right;

    .button-delete {
        width: 100%;
    }
}

.actions:hover {
    color: #000;
}

.project.content {
    &.processing {
        .subtitle {
            color: white;
        }
    }

    .subtitle {
        text-transform: uppercase;
        min-height: 50px;
        line-height: 25px;
    }

    .processing-text {
        color: white;
    }
}
</style>