<template>
    <section class="section">
        <div class="box box-compact login">
            <h1 class="title is-2 has-text-centered pt-3">{{ $t('RegisterConfirm.Title') }}</h1>
            <p class="has-text-centered pt-4" v-if="!hasError">{{ $t('RegisterConfirm.Success') }}</p>
            <p class="has-text-centered pt-4" v-else>{{ $t('RegisterConfirm.Error') }}</p>

            <h2 class="subtitle small is-3 has-text-centered pt-4 mt-4">
                <router-link to="/login">{{ $t('Login.Title') }}</router-link>
            </h2>
        </div>
    </section>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from '../store';
// import Login from '@/components/Login.vue'; // @ is an alias to /src

const route = useRoute();
const store = useStore();

const email = ref('');
const token = ref('');
const hasError = ref(false);
store.commit('app/setLayout', 'centered');

async function verifyRegistration() {
    try {
        await store.dispatch('auth/confirmRegistration', { email: email.value, token: token.value });
    } catch (error) {
        hasError.value = true;
        console.error('Registration verification failed:', error);
    }
}

onMounted(() => {
    const queryEmail = route.query.email as string;
    const queryToken = route.query.token as string;

    if (queryEmail && queryToken) {
        email.value = queryEmail;
        token.value = queryToken;
        verifyRegistration();
    }
});
</script>