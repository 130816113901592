<!-- QRCodeModalContent.vue -->
<template>
  <div class="has-text-centered">
    <p>{{ t('Profile.QRCodeInstructions') }}</p>
    <qrcode-vue :value="qrCodeValue" :image="logo" :image-options="imageOptions" :dots-options="dotsOptions" :qr-options="qrOptions" />
    <p>{{ t('Profile.Enter2FACode') }}</p>
    <div class="field">
      <input id="twoFactorCodeInput" class="input" type="text" :placeholder="t('Profile.2FACodePlaceholder')" />
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { useStore } from '@/store'; // Assuming the store is in @/store
import QrcodeVue from 'qrcode-vue3';

// import logo
import logo from '../assets/Logo.png';

const { t } = useI18n();
const store = useStore();

// Pass the QR code value as a computed property from the store
const qrCodeValue = computed(() => store.state.auth.twoFactorQrCode);

// Customization for imageOptions (logo)
const imageOptions = {
  hideBackgroundDots: true,
  imageSize: 0.3,
  margin: 2,
  // crossOrigin: 'anonymous'
};

// Customization for dotsOptions (black/gray gradient and dots)
const dotsOptions = {
  type: 'dots', // Use 'dots' type for the dots style
  color: '#000000', // Fallback color if gradient isn't supported
  gradient: {
    type: 'radial', // Linear gradient
    rotation: 0, // Horizontal gradient (0 degrees)
    colorStops: [
      { offset: 0, color: '#888888' }, // Black at the start of the gradient
      { offset: 1, color: '#000000' },  // Dark gray at the end of the gradient
    ]
  }
};

// QR Code options (error correction level, mode, etc.)
const qrOptions = {
  typeNumber: 0, // Automatic size based on the data length
  mode: 'Byte',  // Byte mode to handle text efficiently
  errorCorrectionLevel: 'H' // High error correction level
};

</script>