<template>
    <section class="container profile">
        <div class="columns box is-centered" style="max-width: 1024px; min-height:750px; margin: auto;">
            <!-- Left Column (1/3) -->
            <div class="column is-one-third">
                <div class="has-text-centered">
                    <!-- Profile Image -->
                    <div class="profile-image-container" @drop.prevent="handleDrop"
                        @dragover.prevent="throttledHandleDragOver"
                        @dragleave.prevent="throttledHandleDragLeave"
                        @dragenter.prevent="throttledHandleDragEnter"
                        >
                        <figure class="image is-squared is-inline-block p-3">
                            <div class="overlay" :class="{ 'dragged': dragging }">
                                <p class="has-text-white">
                                    <font-awesome-icon icon="upload" /> Upload new Image
                                </p>
                            </div>
                            <img style="height: 280px;" v-show="hasImage" @load="hasImage = true"
                                @error="hasImage = false" class="is-rounded" :src="profileImageUrl" alt="User Profile"
                                @click="triggerFileUpload">
                            <img v-show="!hasImage" class="is-rounded" :src="defaultProfileImageUrl" alt="User Profile"
                                @click="triggerFileUpload">
                        </figure>
                        <input type="file" ref="fileInput" class="is-hidden" @change="handleFileChange" accept="image/*" />
                    </div>
                    <!-- Email -->
                    <p class="has-text-weight-bold mt-2">{{ formData.email }}</p>
                    <!-- Links -->
                    <div class="mt-4">
                        <button class="button is-light is-fullwidth mb-2">Terms Of Service</button>
                        <button class="button is-light is-fullwidth mb-2">Privacy Policy</button>
                        <button class="button is-light is-fullwidth mb-2">Licenses</button>
                        <button class="button is-light is-fullwidth mb-2">Legal Notice</button>
                    </div>
                </div>
            </div>

            <!-- Right Column (2/3) -->
            <div class="column is-two-thirds">
                <div class="">
                    <h2 class="title is-4 has-text-centered">Settings</h2>

                    <!-- First Name -->
                    <!-- <div class="field material-input">
                        <div class="control">
                            <input class="input" type="text" v-model="formData.firstName">
                            <label class="label">First Name</label>
                        </div>
                    </div> -->

                    <!-- Last Name -->
                    <!-- <div class="field material-input">
                        <div class="control">
                            <input class="input" type="text" v-model="formData.lastName">
                            <label class="label">Last Name</label>
                        </div>
                    </div> -->

                    <!-- Newsletter -->
                    <div class="field checkbox-wrapper mt-4">
                        <label class="checkbox-label label switch is-rounded">
                            <span class="control-label">
                                {{ $t('General.Newsletter') }}
                            </span>
                            <input type="checkbox" class="" v-model="formData.hasNewsletter" @change="onSubmit" />
                            <span class="check"></span>
                        </label>
                    </div>

                    <div class="field checkbox-wrapper mt-4">
                        <label class="checkbox-label label switch is-rounded">
                            <span class="control-label">
                                {{ $t('General.Notifications') }}
                            </span>
                            <input type="checkbox" v-model="formData.hasNotifications" @change="onSubmit" />
                            <span class="check"></span>
                        </label>
                    </div>

                    <div class="field checkbox-wrapper mt-4">
                        <label class="checkbox-label label switch is-rounded">
                            <span class="control-label">
                                Two Factor Authentication
                            </span>
                            <input type="checkbox" v-model="formData.twoFactorEnabled" @change="toggle2FA">
                            <span class="check"></span>
                        </label>
                    </div>

                    <div class="field material-select mt-5">
                        <select class="select" v-model="formData.language" @change="onSubmit">
                            <option value="">{{ $t('General.PleaseChoose') }}</option>
                            <option value="en">English</option>
                            <option value="de">Deutsch</option>
                        </select>
                        <label class="label">Language</label>
                        <div class="icon">
                            <font-awesome-icon icon="chevron-down"></font-awesome-icon>
                        </div>
                    </div>

                    <div class="field material-select">
                        <select class="select" v-model="formData.dateFormat" @change="onSubmit">
                            <option value="">{{ $t('General.PleaseChoose') }}</option>
                            <option value="dd.MM.yyyy">dd.MM.yyyy</option>
                            <option value="dd/MM/yyyy">dd/MM/yyyy</option>
                            <option value="MM/dd/yyyy">MM/dd/yyyy</option>
                            <option value="yyyy-MM-dd">yyyy-MM-dd</option>
                        </select>
                        <label class="label">Date Format</label>
                        <div class="icon">
                            <font-awesome-icon icon="chevron-down"></font-awesome-icon>
                        </div>
                    </div>

                    <div v-if="false" class="field material-select">
                        <select class="select" v-model="formData.timezone">
                            <option value="">{{ $t('General.PleaseChoose') }}</option>
                            <option value="UTC">UTC</option>
                            <option value="America/New_York">America/New_York</option>
                            <!-- Add more timezones here -->
                        </select>
                        <label class="label">Time Zone</label>
                        <div class="icon">
                            <font-awesome-icon icon="chevron-down"></font-awesome-icon>
                        </div>
                    </div>

                    <!-- Delete Account -->
                    <div class="field">
                        <button class="button is-danger is-fullwidth" @click="deleteProfile">Delete Account</button>
                        <p class="help is-danger mt-1">DANGER ZONE: Lose access to all your files. Type in your email
                            address to delete account.</p>
                    </div>

                    <!-- Submit Button -->
                    <div v-if="false" class="field has-text-centered">
                        <button class="button is-primary" @click="onSubmit">Save Changes</button>
                    </div>
                </div>
            </div>

        </div>
        
        <div class="columns bottom-line">
            <div class="column has-text-centered">
                Made with ❤️ by Cloud NYNE GmbH | Hadikgasse 64, 1140 Vienna
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, markRaw } from 'vue';
import { useStore } from '../store';
import { useI18n } from 'vue-i18n';
import QRCodeModalContent from './QRCodeModalContent.vue';
import ProfileImage from '../assets/media/profile.png';
import { throttle } from '@/directives/validationDirective';

const { t } = useI18n();
const store = useStore();
store.commit('app/setLayout', 'centered');

const baseImageUrl = "/api/auth/"; // "http://localhost:5212/api/auth/";

const profileImageUrl = ref("");
const defaultProfileImageUrl = ref(ProfileImage);
console.log("defaultProfileImageUrl", defaultProfileImageUrl);
// const hovering = ref(false); // Hover state for the image
const dragging = ref(false); // Dragging state for drag-and-drop functionality
const fileInput = ref<HTMLInputElement | null>(null);
const hasImage = ref(false);

const formData = ref({
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    hasNewsletter: false,
    hasNotifications: false,
    twoFactorEnabled: false,
    language: 'en',
    dateFormat: 'dd/MM/yyyy',
    timezone: 'UTC',
    profileImage: '',
});

const triggerFileUpload = () => {
    fileInput.value?.click(); // Trigger the file input click
};

const handleFileChange = async (event: Event) => {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file) {
        await uploadImage(file); // Call the upload function
    }
};

const handleDrop = async (event: DragEvent) => {
    console.log("Drop event", event);
    const file = event.dataTransfer?.files[0];
    console.log("drop file", file);
    console.log("xx", event.dataTransfer);
    if (file) {
        await uploadImage(file); // Call the upload function
        store.commit('app/showToast', { message: 'Profile-Image uploaded successfully', type: 'success' });
    }else{
        store.commit('app/showToast', { message: 'No file found!', type: 'danger' });
    }
    dragging.value = false;
};

const handleDragOver = (e: any) => {
    e.preventDefault();
    dragging.value = true;
};

const handleDragLeave = (event: DragEvent) => {
    console.log("Drageevent", event);
    dragging.value = false;
};

const handleDragEnter = () => {
    dragging.value = true;
};

const throttledHandleDragOver = throttle(handleDragOver, 100);
const throttledHandleDragLeave = throttle(handleDragLeave, 300);
const throttledHandleDragEnter = throttle(handleDragEnter, 300);


const uploadImage = async (file: File) => {
    const data = new FormData();
    data.append('profileImage', file);

    // Call the API via Vuex store (adjust this to your actual endpoint)
    const response = await store.dispatch('auth/uploadProfileImage', data);
    console.log("response", response);
    if (response.success) {
        const dateInMilliseconds = new Date().getTime();
        profileImageUrl.value = `${baseImageUrl}${formData.value.id}/profile?${dateInMilliseconds}` // Update the profile image preview
        console.log("profileImageUrl", profileImageUrl.value);
        hasImage.value = true;
        store.commit('app/showToast', { message: 'Image uploaded successfully', type: 'success' });
    } else {
        store.commit('app/showToast', { message: 'Failed to upload image', type: 'error' });
    }
};

// Load user profile data on component mount
onMounted(async () => {
    await store.dispatch('auth/getProfile');
    Object.assign(formData.value, store.state.auth.userData);
    profileImageUrl.value = `${baseImageUrl}${store.state.auth.userData?.id}/profile`
});

watch(() => store.state.auth.userData, (newValue) => {
    if (newValue) {
        Object.assign(formData.value, newValue);
        profileImageUrl.value = `${baseImageUrl}${newValue.id}/profile`
    }
});

// Submit form data
const onSubmit = async (event: any) => {
    if (event) {
        event.preventDefault();
    }
    await store.dispatch('auth/updateProfile', formData.value);
    store.commit('app/showToast', {
        message: t('Profile.Success'),
        type: 'success',
    });
};

// Delete profile
const deleteProfile = async (event: any) => {
    if (event) {
        event.preventDefault();
    }
    await store.dispatch('auth/deleteProfile');
    store.commit('app/showToast', {
        message: t('Profile.SuccessAccountDeleted'),
        type: 'success',
    });
};
const toggle2FA = async () => {
    if (formData.value.twoFactorEnabled) {
        // Enable 2FA (Trigger QR code generation and modal display)
        const response = await store.dispatch('auth/generate2FASetup');
        if (response.success && store.state.auth.twoFactorQrCode) {
            // Show modal with QR Code
            store.commit('app/showConfirmDialog', {
                title: t('Profile.Enable2FA'),
                component: markRaw(QRCodeModalContent), // Mark the component as raw to avoid reactivity issues
                action: async () => {
                    // Get the 2FA code entered by the user
                    const twoFactorCode = (document.getElementById('twoFactorCodeInput') as HTMLInputElement)?.value;

                    if (twoFactorCode) {
                        const enableResponse = await store.dispatch('auth/enable2FA', twoFactorCode);
                        if (enableResponse.success) {
                            store.commit('app/showToast', {
                                message: t('Profile.2FAEnabledSuccess'),
                                type: 'success',
                            });
                        } else {
                            formData.value.twoFactorEnabled = false;
                            store.commit('app/showToast', {
                                message: t('Profile.2FAEnabledError'),
                                type: 'danger',
                            });
                        }
                    } else {
                        store.commit('app/showToast', {
                            message: t('Profile.2FACodeRequired'),
                            type: 'danger',
                        });
                    }
                },
                abort: () => {
                    formData.value.twoFactorEnabled = false;
                }
            });
        } else {
            formData.value.twoFactorEnabled = false;
            store.commit('app/showToast', {
                message: t('Profile.Enable2FAError'),
                type: 'danger',
            });
        }
    } else {
        // Disable 2FA
        const response = await store.dispatch('auth/disable2FA');
        if (!response.success) {
            formData.value.twoFactorEnabled = true;
            store.commit('app/showToast', {
                message: t('Profile.Disable2FAError'),
                type: 'error',
            });
        }
    }
};

</script>

<style scoped>
.profile-image-container {
    position: relative;
    cursor: pointer;
}

.profile-image-container:hover .overlay,
.profile-image-container .overlay.dragged {
    display: flex;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    pointer-events: none;
}

.overlay p {
    color: white;
    font-size: 1.2em;
}

input[type="file"] {
    display: none;
}

.container.profile {
    min-height:750px;
    @media screen and (max-width: 768px) {
        min-height: auto;
    }

    .bottom-line{
        margin-top: -45px;
    }
}
</style>