// src/router/index.ts
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import ErrorView from '../views/ErrorView.vue';
import LoginView from '../views/LoginView.vue';
import Projects from '../components/Projects.vue';
import ProjectDetail from '../components/ProjectDetail.vue';
import Teams from '../components/Teams.vue';
import TeamDetail from '../components/TeamDetail.vue';
import UploadData from '../components/UploadData.vue';
// import TeamDetail from '../components/TeamDetail.vue';
import Registration from '../components/Registration.vue';
import RegistrationVerification from '../components/RegistrationVerification.vue';
import RequestPasswordReset from '../components/RequestPasswordReset.vue';
import ResendVerifyRegistration from '../components/ResendVerifyRegistration.vue';
import ResetPassword from '../components/ResetPassword.vue';
import Accounts from '../components/Accounts.vue';
import TusUpload from '../components/TusUpload.vue';
import Profile from '../components/Profile.vue';
import Faq from '../components/Faq.vue';
import Statistics from '@/components/Statistics.vue';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: TusUpload,
    meta: { title: 'Upload.Menu' },
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginMagicLinkView,
    meta: { title: 'Login.Title' }
  },
  {
    path: '/login-email',
    name: 'LoginEmail',
    component: LoginView,
    meta: { title: 'Login.Title' }
  },
  {
    path: '/register',
    name: 'Register',
    component: Registration,
    meta: { title: 'Register.Title' }
  },
  {
    path: '/FAQ',
    name: 'FAQ',
    component: Faq,
    meta: { title: 'Faq.Menu' }
  },
  {
    path: '/register-verify',
    name: 'RegistrationVerification',
    component: RegistrationVerification,
    meta: { title: 'RegisterConfirm.Title' },
    beforeEnter: (to, from, next) => {
      const queryParameters = to.query;
      const requiredParameters = ['email', 'token'];
      const hasRequiredParameters = requiredParameters.every(param => {
        const value = queryParameters[param];
        return value && value.length > 0;
      });

      hasRequiredParameters ? next() : next('/login');
    }
  },
  {
    path: '/resend-verify-registration',
    name: 'ResendVerifyRegistration',
    component: ResendVerifyRegistration,
    meta: { title: 'ResendVerifyRegistration.Title' }
  },
  {
    path: '/request-password-reset',
    name: 'RequestPasswordReset',
    component: RequestPasswordReset,
    meta: { title: 'RequestPasswordReset.Title' }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: { title: 'ResetPassword.Title' },
    beforeEnter: (to, from, next) => {
      const queryParameters = to.query;
      const requiredParameters = ['email', 'token'];
      const hasRequiredParameters = requiredParameters.every(param => {
        const value = queryParameters[param];
        return value && value.length > 0;
      });

      hasRequiredParameters ? next() : next('/login');
    }
  },
  {
    path: '/upload',
    name: 'Upload',
    component: TusUpload,
    meta: { title: 'Upload.Menu' },
  },
  {
    path: '/upload/data/:id',
    name: 'UploadData',
    component: UploadData,
    meta: { title: 'UploadData.Title' },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: { requiresAuth: true, title: 'Profile.Title' },
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects,
    meta: { requiresAuth: true, title: 'Projects.Title' },
  },
  {
    path: '/projectshare/:id',
    name: 'ProjectShare',
    component: ProjectDetailShare,
    meta: { requiresAuth: true, title: 'Projects.Title' },
  },
  {
    path: '/projectbim/:id',
    name: 'ProjectBim',
    component: ProjectBim,
    meta: { requiresAuth: true, title: 'Projects.Title' },
  },
  {
    path: '/project/:id?',
    name: 'ProjectDetail',
    component: ProjectDetail,
    meta: { requiresAuth: true, title: 'Projects.Title' },
  },
  {
    path: '/accounts',
    name: 'Accounts',
    component: Accounts,
    meta: { requiresAuth: true, requiresAdmin: true, title: 'Accounts.Title' },
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: Statistics,
    meta: { requiresAuth: true, requiresAdmin: true, title: 'Statistics.Title' },
  },
  {
    path: '/teams/:id?',
    name: 'TeamDetail',
    component: TeamDetail,
    meta: { requiresAuth: true, title: 'Details' },
  },
  {
    path: '/team',
    name: 'Team',
    component: Teams,
    meta: { requiresAuth: true, title: 'Teams' },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: HomeView,
    meta: { requiresAuth: true, title: 'General.Menu.LogOut' },
  },
  {
    path: '/access-not-allowed',
    name: 'AccessNotAllowed',
    component: ErrorView,
    meta: { requiresAuth: false, title: 'AccessDenied.Title' },
  },
  // Add the catch-all route at the end
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: ErrorView,
    meta: { title: 'AccessDenied.Title' }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

import { store } from '@/store'; // Import the store directly
import { computed } from 'vue';
import LoginMagicLinkView from '@/views/LoginMagicLinkView.vue';
import ProjectDetailShare from '@/components/ProjectDetailShare.vue';
import ProjectBim from '@/components/ProjectBim.vue';
const isAuth = computed(() => store.state.auth.isAuthenticated);
const isAdmin = computed(() => store.state.auth.isAdmin);

router.beforeEach((to, from, next) => {
  const isAuthenticated = isAuth.value ?? false;
  // const isAdmin = isAdmin.value ?? false;

  if (to.meta.requiresAuth && !isAuthenticated) {
    next({ name: 'Login' });
  } else if (to.meta.requiresAdmin && !isAdmin.value) {
    next({ name: 'Verboten' })
  } else if (to.name === 'Login' && isAuthenticated) {
    next({ name: 'Home' });
  } else {
    next();
  }
});

export default router;
